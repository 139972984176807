.Project{
    display: inline-block;
    margin: 10px;
}

/*

.Project img{
    display: inline-block;
    width: 400px;
    border-radius: 20px;
    
    transition: all 0.5s;
}
.Project img:hover{
    border-radius: 0;
    opacity: 2;
}

*/