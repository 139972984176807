.Bio{
    padding: 10rem 0;
}

.about-me{
    text-align: center;
}

.Bio .about-me-content{
    margin: 0 auto;
}

.about-me-content{
    text-align: center;
    width: 60%;
}

#github{
    color: gray;
}
#github:hover{
    color: black;
}
