.Nav{
    color: white;
}

.Logo{
    display: inline;
    margin: 0 6px;
}

.Nav h3{
    display: inline;
    margin: 0 6px;
}

.Nav ul{
    display: inline;
    list-style-type: none;
    float: right;
    margin: 50px 30px 0 0;
}
.Nav a:hover{
    text-decoration: none;
}

.Nav li{
    display: inline;
    margin: 0 10px;
    position: relative;
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.Nav li::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.Nav li:hover::before{
    visibility: visible;
    transform: scaleX(1);
}

@keyframes animate{
    0%{transform: translate(0, -150%); opacity: 0;}
    80%{transform: translate(0, 10%); opacity: 0.8;}
    100%{transform: translate(0, 0); opacity: 1;}
  }
.Nav ul{
    animation: animate 1s ease-in-out;
}

  